import React from "react";
import Image from "gatsby-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import excavatorImg from '../images/miniExcavatorDigging.jpg';

const query = graphql`
  {
    file(relativePath: { eq: "miniExcavatorDigging.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

function NotFoundPage() {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="404: Page Not found" />
      <section>
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            {/* <img className="object-cover object-center rounded-lg" src={excavatorImg} alt="Excavator Digging a Hole"/> */}
            <Image
              className="object-cover object-center rounded-lg"
              fluid={fluid}
              alt="Excavator Digging a Hole"
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Sorry, It Looks Like the Page You're Looking for Isn't Available
            </h1>
            <p className="mb-8 leading-relaxed text-lg">
              Let's keep digging to find what you were looking for. Use the
              buttons below to navigate back to the home page or learn more
              about the services we offer.
            </p>
            <div className="flex justify-center">
              <Link
                to="/"
                className="inline-flex text-white bg-gray-900 border-0 py-2 px-6 focus:outline-none transition duration-500 ease-in-out hover:text-ocyellow rounded text-lg"
              >
                Home
              </Link>
              <Link
                to="/services"
                className="ml-4 inline-flex text-gray-800 bg-gray-300 border-0 py-2 px-6 focus:outline-none transition duration-500 ease-in-out hover:bg-gray-400 rounded text-lg"
              >
                Services
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
